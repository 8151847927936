import { createReducer, on } from '@ngrx/store';

import { loadCategories, loadedCategories, ActionsCategoriesUnion } from '../actions/categories';
import { WpCategory } from '../../interfaces/wp-category';

export interface CategoriesState {
  list: WpCategory[];
  loading: Boolean;
}

const initialState: CategoriesState = {
  list: [],
  loading: false
};

const reducer = createReducer(initialState,
  on(loadCategories, state => ({...state, list: []})),
  on(loadedCategories, (state, {categories}) => ({...state, list: categories})));

export function categoriesReducer(state = initialState, action: ActionsCategoriesUnion) {
  return reducer(state, action);
}
