import { createAction, props, union } from '@ngrx/store';
import { WpPost } from '../../interfaces/wp-post';

export const loadGalleryPosts = createAction('[WP posts gallery] load request', props<{categories: Array<number>}>());
export const loadedGalleryPosts = createAction('[WP posts gallery] loaded', props<{posts: WpPost[]}>());
export const selectGalleryCategory = createAction('[WP posts gallery] select category', props<{category: number|string}>());
export const loadWallPaintingPosts = createAction('[WP posts wall paintings] load request', props<{categories: Array<number>}>());
export const loadedWallPaintingPosts = createAction('[WP posts wall paintings] loaded', props<{posts: WpPost[]}>());
export const loadExhibitionPosts = createAction('[WP posts exhibition] load request', props<{categories: Array<number>}>());
export const loadedExhibitionPosts = createAction('[WP posts exhibition] loaded', props<{posts: WpPost[]}>());
export const selectExhibitionCategory = createAction('[WP posts exhibition] select category', props<{category: number}>());

const actions = union({
  loadGalleryPosts,
  loadedGalleryPosts,
  selectGalleryCategory,
  loadWallPaintingPosts,
  loadedWallPaintingPosts,
  loadExhibitionPosts,
  loadedExhibitionPosts,
  selectExhibitionCategory
});

export type ActionsPostsUnion = typeof actions;
