import { TranslateService } from '@ngx-translate/core';
import { AppSettings } from '../constants';
import Utils, { pageY } from '../utils';
import { fromEvent } from 'rxjs';
import { map, distinctUntilChanged, startWith } from 'rxjs/operators';
var AppDataService = /** @class */ (function () {
    function AppDataService(translate) {
        this.translate = translate;
        this.languages = AppSettings.LANGUAGES;
        this.postsMap = {};
        translate.setDefaultLang('en');
        this.setTranslations();
        translate.use(location.pathname.startsWith('/en') ? 'en' : 'ua');
    }
    AppDataService.prototype.setTranslations = function () {
        this.translate.setTranslation('en', {
            Series: 'Series',
            Download: 'Download',
            All: 'All',
            Title: 'another world',
            Contact: 'Contact',
            JoinSocial: 'Join us',
            FollowSocial: 'Find us in social networks',
            GoShops: 'Visit me',
            Details: 'See more',
            HideDetails: 'Show less',
        });
        this.translate.setTranslation('ua', {
            Series: 'Серії',
            Download: 'Скачати',
            All: 'Усе',
            Title: 'інший світ',
            Contact: 'Контакти',
            JoinSocial: 'Приєднуйтесь',
            FollowSocial: 'Слідкуйте в мережах',
            GoShops: 'Завітайте на',
            Details: 'Показати більше...',
            HideDetails: 'Згорнути',
            Previous: 'Попередній',
            Next: 'Наступний',
            'All posts': 'Усі дописи'
        });
    };
    Object.defineProperty(AppDataService.prototype, "langURLPrefix", {
        get: function () {
            var _this = this;
            return AppSettings.LANGUAGES.find(function (l) { return l.value === _this.langValue; }).path;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppDataService.prototype, "langValue", {
        get: function () {
            return this.translate.currentLang;
        },
        enumerable: true,
        configurable: true
    });
    AppDataService.prototype.isElementTop = function (element) {
        var top_ = pageY() + element.getBoundingClientRect().top;
        return fromEvent(window, 'scroll').pipe(map(function () { return top_ >= pageY(); }), distinctUntilChanged(), startWith(top_ >= pageY()));
    };
    AppDataService.prototype.isElementBottom = function (element) {
        var isBottom = function () {
            var _a = element.getBoundingClientRect(), top = _a.top, height = _a.height;
            return top + height < window.innerHeight + 200;
        };
        return fromEvent(window, 'scroll').pipe(map(function () { return isBottom(); }), distinctUntilChanged(), startWith(isBottom()));
    };
    AppDataService.prototype.getContactsData = function () {
        return {
            contacts: {
                email: 'lidikart22@gmail.com',
                mobile: '+380961721752'
            },
            social: [{
                    network: 'facebook',
                    url: 'https://www.facebook.com/LidikArt'
                }, {
                    network: 'instagram',
                    url: 'https://instagram.com/lidikart'
                }, {
                    network: 'youtube',
                    url: 'https://www.youtube.com/channel/UCTfD6w37zeMgQuJ4fj3o2oA'
                }
                // vk: 'http://vk.com/lidikart',
                // twitter: 'https://twitter.com/Lllidik',
            ],
            shops: [{
                    title: 'behance',
                    image: Utils.imagePath + 'shops/behance-thumb.png',
                    link: 'https://www.behance.net/LidikArt'
                },
            ]
        };
    };
    return AppDataService;
}());
export { AppDataService };
