/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./banner.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../common/directives/img-loader.directive";
import * as i4 from "./banner.component";
import * as i5 from "@ngrx/store";
var styles_BannerComponent = [i0.styles];
var RenderType_BannerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BannerComponent, data: {} });
export { RenderType_BannerComponent as RenderType_BannerComponent };
export function View_BannerComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "banner-wrapper"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpod(4, { open: 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["appImgLoader", "https://lidikart.com/wp-content/themes/lidik-art/images/banner.jpg"], ["class", "banner"]], [[4, "background-size", null], [4, "background-repeat", null], [4, "background-position", null], [4, "background-image", null], [2, "loaded", null]], null, null, null, null)), i1.ɵdid(6, 212992, null, 0, i3.ImgLoaderDirective, [i2.DOCUMENT, i1.ChangeDetectorRef], { appImgLoader: [0, "appImgLoader"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["class", "line"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "banner-wrapper"; var currVal_1 = _ck(_v, 4, 0, i1.ɵunv(_v, 2, 1, i1.ɵnov(_v, 3).transform(_co.show$))); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_7 = "https://lidikart.com/wp-content/themes/lidik-art/images/banner.jpg"; _ck(_v, 6, 0, currVal_7); }, function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 6).bgSize; var currVal_3 = i1.ɵnov(_v, 6).bgRepeat; var currVal_4 = i1.ɵnov(_v, 6).bgPosition; var currVal_5 = i1.ɵnov(_v, 6).bgImage; var currVal_6 = i1.ɵnov(_v, 6).loaded; _ck(_v, 5, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_BannerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-banner", [], null, null, null, View_BannerComponent_0, RenderType_BannerComponent)), i1.ɵdid(1, 49152, null, 0, i4.BannerComponent, [i5.Store], null, null)], null, null); }
var BannerComponentNgFactory = i1.ɵccf("app-banner", i4.BannerComponent, View_BannerComponent_Host_0, {}, {}, []);
export { BannerComponentNgFactory as BannerComponentNgFactory };
