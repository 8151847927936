import { WpPost } from '../../interfaces/wp-post';
import { loadedGalleryPosts, selectGalleryCategory, loadedExhibitionPosts,
  selectExhibitionCategory, loadedWallPaintingPosts, ActionsPostsUnion } from '../actions/posts';
import { createReducer, on } from '@ngrx/store';

interface PostsSection {
  list: WpPost[];
  category: string | number;
}

export interface PostsState {
  gallery: PostsSection;
  exhibition: PostsSection;
  wallPaintings: WpPost[];
}

const initSection: PostsSection = {
  list: [],
  category: null
};

const initialState: PostsState = {
  gallery: {...initSection},
  exhibition: {...initSection},
  wallPaintings: []
};

const reducer = createReducer(initialState,
  on(loadedGalleryPosts, (state, {posts}) => ({
    ...state,
      gallery: {
        ...state.gallery,
        list: posts
      }
  })),
  on(selectGalleryCategory, (state, {category}) => ({
    ...state,
      gallery: {
        ...state.gallery,
        category
      }
  })),
  on(loadedExhibitionPosts, (state, {posts}) => ({
    ...state,
      exhibition: {
        ...state.exhibition,
        list: posts
      }
  })),
  on(selectExhibitionCategory, (state, {category}) => ({
    ...state,
      exhibition: {
        ...state.exhibition,
        category
      }
  })),
  on(loadedWallPaintingPosts, (state, {posts}) => ({
    ...state,
    wallPaintings: posts
  })));

export function postsReducer(state = initialState, action: ActionsPostsUnion) {
  return reducer(state, action);
}
