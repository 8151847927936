import { Action, createAction, props, union } from '@ngrx/store';

export const error = createAction('Error Action', props<{message: string}>());
export const dismissError = createAction('Dismiss Error', props<{message: string}>());

const actions = union({
  error,
  dismissError
});

export type ErrorActionsUnion = typeof actions;
