/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./fancy-album.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../common/directives/img-loader.directive";
import * as i3 from "@angular/common";
import * as i4 from "../../common/pipes/img-url.pipe";
import * as i5 from "./fancy-album.component";
import * as i6 from "@angular/router";
import * as i7 from "../../services/app-data.service";
var styles_FancyAlbumComponent = [i0.styles];
var RenderType_FancyAlbumComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FancyAlbumComponent, data: {} });
export { RenderType_FancyAlbumComponent as RenderType_FancyAlbumComponent };
function View_FancyAlbumComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [["data-fancybox", "gallery"]], [[8, "href", 4], [8, "className", 0], [1, "data-pic-id", 0], [8, "title", 0]], null, null, null, null)), i1.ɵppd(1, 2), (_l()(), i1.ɵeld(2, 0, null, null, 2, "img", [], [[4, "background-size", null], [4, "background-repeat", null], [4, "background-position", null], [4, "background-image", null], [2, "loaded", null]], null, null, null, null)), i1.ɵdid(3, 212992, null, 0, i2.ImgLoaderDirective, [i3.DOCUMENT, i1.ChangeDetectorRef], { appImgLoader: [0, "appImgLoader"] }, null), i1.ɵppd(4, 2)], function (_ck, _v) { var _co = _v.component; var currVal_9 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent, 0), _v.parent.context.$implicit, _co.sizeVal)); _ck(_v, 3, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent.parent, 0), _v.parent.context.$implicit, "src")), ""); var currVal_1 = ("thumbnail fancybox slide " + _v.parent.context.$implicit.format); var currVal_2 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.id, ""); var currVal_3 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.title.rendered, ""); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = i1.ɵnov(_v, 3).bgSize; var currVal_5 = i1.ɵnov(_v, 3).bgRepeat; var currVal_6 = i1.ɵnov(_v, 3).bgPosition; var currVal_7 = i1.ɵnov(_v, 3).bgImage; var currVal_8 = i1.ɵnov(_v, 3).loaded; _ck(_v, 2, 0, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
function View_FancyAlbumComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "image text-center"]], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "col-12 col-xl-5": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FancyAlbumComponent_2)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "image text-center"; var currVal_1 = _ck(_v, 3, 0, (_co.sizeVal === "big")); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_FancyAlbumComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i4.ImgUrlPipe, []), i1.ɵqud(402653184, 1, { wrapper: 0 }), (_l()(), i1.ɵeld(2, 0, [[1, 0], ["wrapper", 1]], null, 5, "div", [["class", "album-wrapper row justify-content-center no-gutters"]], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵncd(null, 0), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FancyAlbumComponent_1)), i1.ɵdid(7, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "album-wrapper row justify-content-center no-gutters"; var currVal_1 = _co.sizeClass; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = _co.displayPictures; _ck(_v, 7, 0, currVal_2); }, null); }
export function View_FancyAlbumComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-fancy-album", [], null, null, null, View_FancyAlbumComponent_0, RenderType_FancyAlbumComponent)), i1.ɵdid(1, 245760, null, 0, i5.FancyAlbumComponent, [i6.ActivatedRoute, i6.Router, i7.AppDataService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FancyAlbumComponentNgFactory = i1.ɵccf("app-fancy-album", i5.FancyAlbumComponent, View_FancyAlbumComponent_Host_0, { items: "items", pic: "pic", size: "size" }, {}, ["*"]);
export { FancyAlbumComponentNgFactory as FancyAlbumComponentNgFactory };
