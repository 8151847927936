import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import { loadCategories, loadedCategories } from '../actions/categories';
import { WpService } from '../../services/wp.service';
import { error } from '../actions';

@Injectable()
export class CategoriesEffects {
  constructor(private actions: Actions, private wpService: WpService) {}

  categories$ = createEffect(() => this.actions.pipe(
    ofType(loadCategories),
    switchMap(() => this.wpService.loadCategories()),
    map(categories => loadedCategories({categories})),
    catchError(e => of(error({message: 'Categories failed to load correctly.'})))));
}
