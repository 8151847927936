import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { of, Subject } from 'rxjs';
import { AppDataService } from './app-data.service';
import { environment } from '../../environments/environment';
import { switchMap, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./app-data.service";
var SERVICE_URL = environment.SERVICE_URL;
var WpService = /** @class */ (function () {
    function WpService(http, appService) {
        this.http = http;
        this.appService = appService;
    }
    Object.defineProperty(WpService.prototype, "params", {
        get: function () {
            return {
                per_page: '100',
                lang: this.appService.langValue,
                page: '1'
            };
        },
        enumerable: true,
        configurable: true
    });
    /** @deprecated */
    WpService.prototype.loadPostsByCategories2 = function (categoriesIds, params) {
        var _this = this;
        if (params === void 0) { params = this.params; }
        return this.http.get(SERVICE_URL + "/posts", {
            params: tslib_1.__assign({}, params, { categories: categoriesIds.map(String).join(',') })
        }).pipe(switchMap(function (res) {
            if (res.length === +params.per_page) {
                var page = parseInt(params.page, 10);
                page = (++page).toString();
                return _this.loadPostsByCategories2(categoriesIds, tslib_1.__assign({}, params, { page: page })).pipe(map(function (nextRes) {
                    return res.concat(nextRes);
                }));
            }
            return of(res);
        }));
    };
    WpService.prototype.loadPostsByCategories = function (categoriesIds, params) {
        if (params === void 0) { params = this.params; }
        var sub = new Subject();
        this.loadPosts(categoriesIds, params, sub).subscribe();
        return sub;
    };
    WpService.prototype.loadPosts = function (categoriesIds, params, sub, prevData) {
        var _this = this;
        if (params === void 0) { params = this.params; }
        if (prevData === void 0) { prevData = []; }
        params.per_page = '20';
        return this.http.get(SERVICE_URL + "/posts", {
            params: tslib_1.__assign({}, params, { categories: categoriesIds.map(String).join(',') })
        }).pipe(switchMap(function (res) {
            var loadedData = prevData.concat(res);
            sub.next(loadedData);
            if (res.length === +params.per_page) {
                var page = parseInt(params.page, 10);
                page = (++page).toString();
                return _this.loadPosts(categoriesIds, tslib_1.__assign({}, params, { page: page }), sub, loadedData);
            }
            else {
                sub.complete();
                return of(res);
            }
        }));
    };
    WpService.prototype.loadPages = function () {
        return this.http.get(SERVICE_URL + "/pages", { params: this.params });
    };
    WpService.prototype.loadCategories = function () {
        return this.http.get(SERVICE_URL + "/categories", { params: this.params });
    };
    WpService.prototype.loadBlogs = function () {
        return this.http.get(SERVICE_URL + "/blogs", { params: this.params });
    };
    WpService.prototype.loadBlog = function (id) {
        return this.http.get(SERVICE_URL + "/blogs/" + id, { params: this.params });
    };
    WpService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WpService_Factory() { return new WpService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AppDataService)); }, token: WpService, providedIn: "root" });
    return WpService;
}());
export { WpService };
