<nav class="navbar navbar-expand-lg navbar-light justify-content-center p-0 align-items-stretch">
  <a class="navbar-brand px-2" [routerLink]="dataService.langURLPrefix">
    <h1 class="text-center mb-0 la-label">LidikART<span class="ng-scope d-block">{{'Title' | translate }}</span></h1>
    <div class="logo-image d-none d-sm-inline-block"></div>
  </a>
  <button class="navicon-button x d-lg-none" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
    <div class="navicon"></div>
  </button>
  <div class="collapse navbar-collapse align-items-stretch" id="navbarNavAltMarkup">
    <div class="navbar-nav dropdown align-items-center" *ngFor="let page of (pages | async)" routerLinkActive="active" [ngClass]="{active2: page.slug === 'gallery' && router.url === ('/' + dataService.langURLPrefix)}">
      <a class="nav-item nav-link w-100 text-center p-md-2 py-2" [routerLink]="page.link">{{page.title.rendered}}</a>
    </div>
    <div class="dropdown-divider"></div>
    <div *ngFor="let lang of languages" class="navbar-nav dropdown d-lg-none">
      <a class="nav-item nav-link text-center p-md-2 py-2" (click)="selectLanguage(lang.value)" [ngClass]="{active: lang.value===dataService.langValue}">{{lang.label}}</a>
    </div>
  </div>
  <ul class="lang d-none d-lg-flex p-0 m-0 align-items-center">
    <li *ngFor="let lang of languages" [ngClass]="{active: lang.value===dataService.langValue}" class="px-2">
      <a (click)="selectLanguage(lang.value)">{{lang.label}}</a>
    </li>
  </ul>
</nav>
