<div class="about-description p-4">
  <div *ngIf="(pageData$ | async) as page" class="about-content" [innerHtml]="page.content.rendered"></div>
  <div class="row">
    <div class="col-6">
      <div class="fb-like"
        data-href="https://lidikart.com"
        data-layout="standard"
        data-action="like"
        data-show-faces="false">
      </div>
    </div>
    <div class="col-6">
      <a [href]="resumeLink$ | async"target="_blank" class="pdf-link">
        <span translate>Download</span>
        <span class="la-icon pdf md"></span>
      </a>
    </div>
  </div>
</div>
