import { createAction, props, union } from '@ngrx/store';
import { WpCategory } from '../../interfaces/wp-category';

export const loadCategories = createAction('[WP categories] load');
export const loadedCategories = createAction('[WP categories] loaded successfully', props<{categories: WpCategory[]}>());

const actions = union({
  loadCategories,
  loadedCategories
});

export type ActionsCategoriesUnion = typeof actions;
