import { createAction, props, union } from '@ngrx/store';
import { WpPage } from '../../interfaces/wp-page';

export const loadPages = createAction('[WP] load pages request');
export const loadedPages = createAction('[WP] loaded pages successfully', props<{pages: WpPage[]}>());

const actions = union({
  loadPages,
  loadedPages
});

export type PageActionsUnion = typeof actions;
