<div *ngIf="exhibition$ | async as exhibition" class="album-container exhibition-container">
    <h3 *ngIf="exhibition.name" class="p-2 text-center">{{exhibition.name}}</h3>
    <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 poster-wrapper p-0">
        <div class="row">
            <app-fancy-album [pic]="pic" size="small" [items]="pictures$" class="exhibition-album">
                <div class="col-sm-12 col-md-6">
                    <img class="banner w-100" [src]="exhibition.img" alt=""/>
                    <div [innerHTML]="exhibition.acf.additional_information"></div>
                </div>
                <div class="description col-lg-6 col-md-6 col-xs-12 col-sm-12 pl-4 text-justify" *ngIf="exhibition.description">
                    <span>{{exhibition.description}}</span>
                </div>
            </app-fancy-album>
        </div>
    </div>
</div>
