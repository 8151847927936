<div #wrapper class="album-wrapper row justify-content-center no-gutters" [ngClass]="sizeClass">
  <ng-content></ng-content>
  <div *ngFor="let item of displayPictures" class="image text-center" [ngClass]="{'col-12 col-xl-5': sizeVal === 'big'}">
    <a *ngIf="item" href="{{item | imgUrl:'src'}}"
      [class]="'thumbnail fancybox slide ' + item.format"
      attr.data-pic-id="{{item.id}}"
      data-fancybox="gallery"
      title="{{item.title.rendered}}">
      <img [appImgLoader]="item | imgUrl: sizeVal">
    </a>
  </div>
</div>
