import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AppState, selectPageCategories } from '../../store/reducers';
import { WpCategory } from '../../interfaces/wp-category';

@Component({
  selector: 'app-exhibitions',
  templateUrl: './exhibitions.component.html',
  styleUrls: ['./exhibitions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExhibitionsComponent implements OnInit {
  exhibitions$: Observable<WpCategory[]>;

  private readonly slug = 'exhibitions';
  constructor(private store: Store<AppState>) { }

  ngOnInit() {
    this.exhibitions$ = this.store.select(selectPageCategories(this.slug));
  }
}
