/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./statement.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./statement.component";
import * as i4 from "@ngrx/store";
var styles_StatementComponent = [i0.styles];
var RenderType_StatementComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StatementComponent, data: {} });
export { RenderType_StatementComponent as RenderType_StatementComponent };
function View_StatementComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "statement-content text-justify"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.ngIf.content.rendered; _ck(_v, 0, 0, currVal_0); }); }
export function View_StatementComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "statement p-4"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_StatementComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.pageData$)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_StatementComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-statement", [], null, null, null, View_StatementComponent_0, RenderType_StatementComponent)), i1.ɵdid(1, 49152, null, 0, i3.StatementComponent, [i4.Store], null, null)], null, null); }
var StatementComponentNgFactory = i1.ɵccf("app-statement", i3.StatementComponent, View_StatementComponent_Host_0, {}, {}, []);
export { StatementComponentNgFactory as StatementComponentNgFactory };
