<div class="row">
  <div class="sub-navigation col-lg-2 col-xl-1 position-relative pr-0">
    <ul appFixed *ngIf="(categories$ | async)?.length" class="nav d-none d-lg-block">
      <li class="nav-item py-2">
        <a [routerLink]="[urlPath]" class="nav-link px-0" (click)="menuClick()" [ngClass]="{active: !(serie$ | async)}" translate>All</a>
      </li>
      <li *ngFor="let cat of (menuCategories$ | async); let i = index" class="nav-item py-2">
        <a *ngIf="cat.id" [routerLink]="[urlPath, {'serie': cat.id}]" (click)="menuClick()" class="nav-link px-0" [ngClass]="{active: (serie$ | async) && cat.id === (serie$ | async).id}">{{cat.name}}</a>
      </li>
    </ul>
    <div class="dropdown d-lg-none text-center p-1">
      <span translate>Series</span>:
      <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <ng-container *ngIf="(serie$ | async) as serie">
            {{serie.name}}
        </ng-container>
        <ng-container *ngIf="!(serie$ | async)"><span translate>All</span></ng-container>
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <a [routerLink]="[urlPath]" class="nav-link" [ngClass]="{active: !(serie$ | async)}" translate>All</a>
        <ng-container *ngFor="let cat of (menuCategories$ | async); let i = index">
          <a *ngIf="cat.id" [routerLink]="[urlPath, {'serie': cat.id}]"  class="nav-link" [ngClass]="{active: (serie$ | async) && cat.id === (serie$ | async).id}">{{cat.name}}</a>
        </ng-container>
      </div>
    </div>
  </div>
  <app-fancy-album [pic]="albumState.pic" [items]="pictures" class="col-lg-9 col-xl-11"></app-fancy-album>
</div>
