import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import { loadPages, loadedPages } from '../actions/pages';
import { error } from '../actions';
import { WpService } from '../../services/wp.service';

@Injectable()
export class PagesEffects {
  constructor(private actions$: Actions, private wpService: WpService) {}

  pages$ = createEffect(() => this.actions$.pipe(
    ofType(loadPages),
    switchMap(() => this.wpService.loadPages()),
    map(pages => loadedPages({pages})),
    catchError(e => of(error({message: 'Pages failed to load correctly.'})))));
}
