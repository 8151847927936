import { Router } from '@angular/router';
import { ROUTES } from './app.routes';
import { multilangRoutes } from '../utils';
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule(router) {
        var _a;
        this.router = router;
        (_a = this.router.config).unshift.apply(_a, multilangRoutes(ROUTES));
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
