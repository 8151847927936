import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { switchMap, map, filter } from 'rxjs/operators';
import { AppDataService } from '../../services/app-data.service';
import { selectCategoryById, selectExhibitionImages } from '../../store/reducers';
import { loadExhibitionPosts } from '../../store/actions/posts';
import Utils from '../../utils';
var ExhibitionComponent = /** @class */ (function () {
    function ExhibitionComponent(store, dataService, route) {
        var _this = this;
        this.store = store;
        this.dataService = dataService;
        this.route = route;
        this.exhibition$ = this.route.params.pipe(switchMap(function (_a) {
            var pic = _a.pic, id = _a.id;
            var catId = Number(id);
            _this.pic = pic;
            _this.store.dispatch(loadExhibitionPosts({ categories: [catId] }));
            return _this.store.pipe(select(selectCategoryById, catId));
        }), filter(function (exhibition) { return Boolean(exhibition); }), map(function (exhibition) {
            exhibition.description = Utils.translate(exhibition.description, _this.dataService.langValue);
            return exhibition;
        }));
        this.pictures$ = this.store.select(selectExhibitionImages);
    }
    return ExhibitionComponent;
}());
export { ExhibitionComponent };
