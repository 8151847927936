import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';

import { AppDataService } from './app-data.service';
import { WpPage } from '../interfaces/wp-page';
import { WpCategory } from '../interfaces/wp-category';
import { WpPost } from '../interfaces/wp-post';
import { environment } from '../../environments/environment';
import { switchMap, map } from 'rxjs/operators';

const {SERVICE_URL} = environment;

@Injectable({
  providedIn: 'root'
})
export class WpService {

  get params() {
    return {
      per_page: '100',
      lang: this.appService.langValue,
      page: '1'
    };
  }

  constructor(private http: HttpClient, private appService: AppDataService) {}

  /** @deprecated */
  loadPostsByCategories2(categoriesIds: Array<number>, params = this.params): Observable<WpPost[]> {
    return this.http.get<WpPost[]>(`${SERVICE_URL}/posts`, {
      params: {...params, categories: categoriesIds.map(String).join(',')}}).pipe(switchMap(res => {
        if ((res as Array<any>).length === +params.per_page) {
          let page: number|string = parseInt(params.page, 10);
          page = (++page).toString();
          return this.loadPostsByCategories2(categoriesIds, {...params, page}).pipe(map(nextRes => {
            return [...res, ...(nextRes as WpPost[])] as WpPost[];
          })) as Observable<WpPost[]>;
        }
        return of(res);
      }));
  }

  loadPostsByCategories(categoriesIds: Array<number>, params = this.params): Observable<WpPost[]> {
    const sub = new Subject<WpPost[]>();
    this.loadPosts(categoriesIds, params, sub).subscribe();
    return sub;
  }

  loadPosts(categoriesIds: Array<number>, params = this.params, sub: Subject<WpPost[]>, prevData = []): Observable<WpPost[]> {
    params.per_page = '20';
    return this.http.get<WpPost[]>(`${SERVICE_URL}/posts`, {
      params: {...params, categories: categoriesIds.map(String).join(',')}}).pipe(switchMap(res => {
        const loadedData = [...prevData, ...res];
        sub.next(loadedData);
        if ((res as Array<any>).length === +params.per_page) {
          let page: number|string = parseInt(params.page, 10);
          page = (++page).toString();
          return this.loadPosts(categoriesIds, {...params, page}, sub, loadedData) as Observable<WpPost[]>;
        } else {
          sub.complete();
          return of(res);
        }
      }));
  }

  loadPages(): Observable<WpPage[]> {
    return this.http.get<WpPage[]>(`${SERVICE_URL}/pages`, {params: this.params});
  }

  loadCategories(): Observable<WpCategory[]> {
    return this.http.get<WpCategory[]>(`${SERVICE_URL}/categories`, {params: this.params});
  }

  loadBlogs(): Observable<[]> {
    return this.http.get<[]>(`${SERVICE_URL}/blogs`, {params: this.params});
  }

  loadBlog(id: string|number): Observable<[]> {
    return this.http.get<[]>(`${SERVICE_URL}/blogs/${id}`, {params: this.params});
  }
}
