<div class="row grids exhibitions text-center mt-4 mr-0 ml-0">
    <div class="col-md-5 col-xs-12 offset-md-1 pb-4 exhibition" *ngFor="let exhibition of (exhibitions$ | async)">
        <div class="view view-tenth">
            <a [routerLink]="exhibition.id">
                <div class="exhibition-image pt-1">
                  <h5>{{exhibition.name}}</h5>
                  <div class="la-img" [appImgLoader]="exhibition.img"></div>
                </div>
            </a>
        </div>
    </div>
</div>
