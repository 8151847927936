import { Store } from '@ngrx/store';
import { AppDataService } from '../../services/app-data.service';
import { selectPages } from '../../store/reducers';
import { environment } from '../../../environments/environment';
var FooterComponent = /** @class */ (function () {
    function FooterComponent(store, dataService) {
        this.store = store;
        this.dataService = dataService;
        this.social = [];
        this.pages = this.store.select(selectPages);
        var _a = this.dataService.getContactsData(), social = _a.social, contacts = _a.contacts;
        this.social = social;
        this.contacts = contacts;
        this.version = environment.VERSION;
    }
    Object.defineProperty(FooterComponent.prototype, "currentYear", {
        get: function () {
            return (new Date()).getFullYear();
        },
        enumerable: true,
        configurable: true
    });
    return FooterComponent;
}());
export { FooterComponent };
