<div class="container-fluid p-0">
  <ngx-spinner bdOpacity=0.9 bdColor="#F6F6F6" size="medium" color="#822850" type="ball-spin-fade" [fullScreen]="true">
    <h1 class="la-label">LidikArt</h1>
  </ngx-spinner>
  <div class="row app-content align-top no-gutters">
    <div #header class="col-xl-10 offset-xl-1 col-lg-10 offset-lg-1 col-md-12">
      <app-navigation></app-navigation>
    </div>
    <app-banner #banner class="col-12"></app-banner>
    <div [@pagesAnimation]="getState(o)" class="col-md-10 offset-md-1 app-content overflow-hidden"
      [ngStyle]="{'min-height': contentHeight}">
      <router-outlet #o="outlet" class="route-content"></router-outlet>
    </div>
    <div #footer class="col-12">
      <app-footer [show]="!!contentHeight"></app-footer>
    </div>
  </div>
</div>
<ng-container *ngIf="(errorList$ | async) as errorList">
  <div class="error-list px-3 pb-0 pt-3" *ngIf="errorList.length">
    <div class="alert alert-danger alert-dismissible" role="alert" aria-label="Close" *ngFor="let error of errorList">
      Error message: {{error}}
      Try to reload page or check internet connection.
      <button type="button" class="close" (click)="dismissError(error)" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
</ng-container>
