/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./biography.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./biography.component";
import * as i5 from "@ngrx/store";
import * as i6 from "../../services/app-data.service";
var styles_BiographyComponent = [i0.styles];
var RenderType_BiographyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BiographyComponent, data: {} });
export { RenderType_BiographyComponent as RenderType_BiographyComponent };
function View_BiographyComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "about-content"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.ngIf.content.rendered; _ck(_v, 0, 0, currVal_0); }); }
export function View_BiographyComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "about-description p-4"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_BiographyComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 9, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "col-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "fb-like"], ["data-action", "like"], ["data-href", "https://lidikart.com"], ["data-layout", "standard"], ["data-show-faces", "false"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 6, "div", [["class", "col-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 5, "a", [["class", "pdf-link"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(10, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(11, 8536064, null, 0, i3.TranslateDirective, [i3.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Download"])), (_l()(), i1.ɵeld(13, 0, null, null, 0, "span", [["class", "la-icon pdf md"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.pageData$)); _ck(_v, 2, 0, currVal_0); var currVal_2 = ""; _ck(_v, 11, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform(_co.resumeLink$)); _ck(_v, 8, 0, currVal_1); }); }
export function View_BiographyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-biography", [], null, null, null, View_BiographyComponent_0, RenderType_BiographyComponent)), i1.ɵdid(1, 114688, null, 0, i4.BiographyComponent, [i5.Store, i6.AppDataService, i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BiographyComponentNgFactory = i1.ɵccf("app-biography", i4.BiographyComponent, View_BiographyComponent_Host_0, {}, {}, []);
export { BiographyComponentNgFactory as BiographyComponentNgFactory };
