/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./exhibitions.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../../common/directives/img-loader.directive";
import * as i5 from "./exhibitions.component";
import * as i6 from "@ngrx/store";
var styles_ExhibitionsComponent = [i0.styles];
var RenderType_ExhibitionsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ExhibitionsComponent, data: {} });
export { RenderType_ExhibitionsComponent as RenderType_ExhibitionsComponent };
function View_ExhibitionsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "col-md-5 col-xs-12 offset-md-1 pb-4 exhibition"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "view view-tenth"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "exhibition-image pt-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "la-img"]], [[4, "background-size", null], [4, "background-repeat", null], [4, "background-position", null], [4, "background-image", null], [2, "loaded", null]], null, null, null, null)), i1.ɵdid(8, 212992, null, 0, i4.ImgLoaderDirective, [i3.DOCUMENT, i1.ChangeDetectorRef], { appImgLoader: [0, "appImgLoader"] }, null)], function (_ck, _v) { var currVal_2 = _v.context.$implicit.id; _ck(_v, 3, 0, currVal_2); var currVal_9 = _v.context.$implicit.img; _ck(_v, 8, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit.name; _ck(_v, 6, 0, currVal_3); var currVal_4 = i1.ɵnov(_v, 8).bgSize; var currVal_5 = i1.ɵnov(_v, 8).bgRepeat; var currVal_6 = i1.ɵnov(_v, 8).bgPosition; var currVal_7 = i1.ɵnov(_v, 8).bgImage; var currVal_8 = i1.ɵnov(_v, 8).loaded; _ck(_v, 7, 0, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_ExhibitionsComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "row grids exhibitions text-center mt-4 mr-0 ml-0"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ExhibitionsComponent_1)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.exhibitions$)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ExhibitionsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-exhibitions", [], null, null, null, View_ExhibitionsComponent_0, RenderType_ExhibitionsComponent)), i1.ɵdid(1, 114688, null, 0, i5.ExhibitionsComponent, [i6.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ExhibitionsComponentNgFactory = i1.ɵccf("app-exhibitions", i5.ExhibitionsComponent, View_ExhibitionsComponent_Host_0, {}, {}, []);
export { ExhibitionsComponentNgFactory as ExhibitionsComponentNgFactory };
