import { createSelector, createFeatureSelector, createReducer, on } from '@ngrx/store';
import { pagesReducer } from './pages';
import { categoriesReducer } from './categories';
import { postsReducer } from './posts';
import { error, dismissError } from '../actions';
import { routerReducer } from '@ngrx/router-store';
export * from './pages';
export * from './categories';
export * from './posts';
export var reducers = {
    pages: pagesReducer,
    categories: categoriesReducer,
    posts: postsReducer,
    errorList: errorReducer,
    router: routerReducer
};
var ɵ0 = function (state, _a) {
    var message = _a.message;
    return ([message].concat(state));
}, ɵ1 = function (state, _a) {
    var message = _a.message;
    return state.filter(function (e) { return e !== message; });
};
var reducer = createReducer([], on(error, ɵ0), on(dismissError, ɵ1));
export function errorReducer(state, action) {
    if (state === void 0) { state = []; }
    return reducer(state, action);
}
var CustomSerializer = /** @class */ (function () {
    function CustomSerializer() {
    }
    CustomSerializer.prototype.serialize = function (routerState) {
        var route = routerState.root;
        while (route.firstChild) {
            route = route.firstChild;
        }
        var url = routerState.url, queryParams = routerState.root.queryParams;
        var params = route.params;
        // Only return an object including the URL, params and query params
        // instead of the entire snapshot
        return { url: url, params: params, queryParams: queryParams, route: route };
    };
    return CustomSerializer;
}());
export { CustomSerializer };
var filterCategories = function (page, list) {
    if (page && list) {
        return list.filter(function (i) { return page.categories.includes(i.id); });
    }
    return [];
};
var ɵ2 = filterCategories;
var checkFormat = function (post) { return (post.better_featured_image || post.format === 'video'); };
var ɵ3 = checkFormat;
export var selectPages = function (state) {
    return state.pages.list
        .filter(function (page) { return page.slug !== 'production'; }).sort(function (a, b) {
        return a.menu_order - b.menu_order;
    });
};
export var selectCategories = function (state) { return state.categories.list; };
export var selectGallery = function (state) { return state.posts.gallery; };
export var selectWallPaintings = function (state) { return state.posts.wallPaintings; };
export var selectExhibitionPosts = function (state) { return state.posts.exhibition; };
export var pageSelector = function (slug) { return createSelector(selectPages, function (pages) { return pages.find(function (p) { return p.slug === slug; }); }); };
var ɵ4 = function (pages, slug) { return pages.find(function (p) { return p.slug === slug; }); };
export var selectPageBySlug = createSelector(selectPages, ɵ4);
var ɵ5 = function (gallery) { return gallery.list.filter(function (post) {
    var cat = gallery.category;
    return checkFormat(post) && (!cat || post.categories.includes(cat));
}); };
export var selectGalleryImages = createSelector(selectGallery, ɵ5);
var ɵ6 = function (wallPaintings) { return wallPaintings.filter(checkFormat); };
export var selectWallPaintingsImages = createSelector(selectWallPaintings, ɵ6);
var ɵ7 = function (exhibition) { return exhibition.list.filter(function (post) {
    var cat = exhibition.category;
    return checkFormat(post) && (!cat || post.categories.includes(cat));
}); };
export var selectExhibitionImages = createSelector(selectExhibitionPosts, ɵ7);
// export const selectBlogs = (state: AppState) => state.blogs.list;
// export const selectBlog = createSelector(
//   selectBlogs,
//   (blogs: any[], id) => blogs.find(b => b.id === id));
export var selectPageCategories = function (slug) { return createSelector(pageSelector(slug), selectCategories, filterCategories); };
var ɵ8 = function (categories, id) { return categories.find(function (c) { return c.id === id; }); };
export var selectCategoryById = createSelector(selectCategories, ɵ8);
export var selectRouterState = function (state) { return state.router; };
export var selectReducerState2 = createFeatureSelector('router');
var ɵ9 = function (s) { return s ? s.state : null; };
export var selectRouterInfo = createSelector(selectRouterState, ɵ9);
var ɵ10 = function (s) { return s && s.route ? s.route.data : {}; };
export var selectRouteData = createSelector(selectRouterInfo, ɵ10);
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10 };
