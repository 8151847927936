import { WpPage } from '../../interfaces/wp-page';
import { PageActionsUnion, loadPages, loadedPages } from '../actions/pages';
import { createReducer, on } from '@ngrx/store';

export interface PagesState {
  list: WpPage[];
  loading: Boolean;
}

const initialState: PagesState = {
  list: [],
  loading: false
};

const reducer = createReducer(initialState,
  on(loadPages, (state) => ({
    ...state,
    loading: true
  })),
  on(loadedPages, (state, {pages}) => ({
    ...state,
      loading: false,
      list: pages
  })));

export function pagesReducer(state = initialState, action: PageActionsUnion) {
  return reducer(state, action);
}
