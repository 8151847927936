import * as tslib_1 from "tslib";
import { AppSettings } from './constants';
var Utils = /** @class */ (function () {
    function Utils() {
    }
    Utils.translate = function (str, lang) {
        if (str) {
            lang = lang === 'ua' ? 'uk' : lang;
            var regString = '<\!--\:' + lang + '-->(.*?)<\!--\:-->', re = new RegExp(regString, 'g'), translated = re.exec(str.replace(/(\r\n|\n|\r)/gm, ''));
            if (translated) {
                return translated[1];
            }
        }
        return str;
    };
    Utils.imagePath = '../../../assets/images/';
    return Utils;
}());
export default Utils;
export function getTextFromHtml(html) {
    var div = document.createElement('DIV');
    div.innerHTML = html;
    return div.textContent;
}
export function getShortText(text, len) {
    if (len === void 0) { len = 300; }
    if (text.length > len) {
        return text.substr(0, len) + "...";
    }
    return text;
}
export function pageY() {
    return window.pageYOffset || document.documentElement.scrollTop;
}
export function multilangRoutes(routes) {
    return routes.reduce(function (multiRoutes, route) {
        var lRoutes = AppSettings.LANGUAGES.filter(function (l) { return l.path; }).map(function (language) {
            var lRoute = tslib_1.__assign({}, route);
            lRoute.path = language.path + (language.path && lRoute.path ? '/' : '') + lRoute.path;
            return lRoute;
        });
        lRoutes.forEach(function (lRoute) {
            multiRoutes = multiRoutes.concat(lRoute);
        });
        return multiRoutes;
    }, []).slice();
}
