
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {

  private count = 0;

  constructor(private spinner: NgxSpinnerService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isFirstPage = [null, '1'].includes(req.params.get('page'));
    if (isFirstPage) {
      this.count++;
    }
    if (this.count > 0) {
      this.spinner.show();
    }

    return next.handle(req).pipe(
      finalize(() => {
        if (isFirstPage) {
          this.count--;
        }
        if (this.count === 0) {
          this.spinner.hide();
        }
      })
    );
  }
}
