import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AppSettings } from '../../constants';
import { AppDataService } from '../../services/app-data.service';
import { switchMap, tap } from 'rxjs/operators';
var loadImagesCount = 6;
var FancyAlbumComponent = /** @class */ (function () {
    function FancyAlbumComponent(route, router, appService, changeDetection) {
        var _this = this;
        this.route = route;
        this.router = router;
        this.appService = appService;
        this.changeDetection = changeDetection;
        this.preventDownload = function () { return false; };
        this.pictures = [];
        this.displayPictures = [];
        this.fancyConfig = {
            'beforeShow.fb': function (e, instance, current, firstRun) {
                _this.pic = current.opts.picId;
                _this.routeParams.pic = _this.pic;
                _this.router.navigate([_this.url || AppSettings.ROUTE.GALLERY, _this.routeParams]);
            },
            'afterLoad.fb': function () {
                var el = document.getElementById("fb-like-" + _this.pic);
                el.setAttribute('data-href', location.href);
                setTimeout(function () { return window['FB'].XFBML.parse(el.parentNode); });
            },
            'beforeClose.fb': function () {
                _this.pic = null;
                delete _this.routeParams.pic;
                if (Object.keys(_this.routeParams).length) {
                    _this.router.navigate([_this.url, _this.routeParams]);
                }
                else {
                    _this.router.navigate([_this.url]);
                }
            }
        };
    }
    FancyAlbumComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.url.subscribe(function (url) {
            _this.url = url.map(function (us) { return us.path; }).join('/');
        });
        this.route.params.subscribe(function (params) {
            _this.routeParams = tslib_1.__assign({}, params);
            delete _this.routeParams.id;
            _this.pic = +params.pic;
            if (!_this.pic) {
                jQuery.fancybox.close();
            }
        });
        this.items.pipe(tap(function (items) {
            var isNewPictures = !_this.pictures.every(function (p, i) { return items[i] === p; });
            if (isNewPictures) {
                _this.displayPictures = [];
                _this.changeDetection.markForCheck();
            }
            _this.pictures = items;
        }), switchMap(function () { return _this.appService.isElementBottom(_this.wrapper.nativeElement); })).subscribe(function (isBottom) {
            var displayLen = _this.displayPictures.length;
            var length = _this.pictures.length;
            if ((isBottom && displayLen !== length) || (displayLen < loadImagesCount)) {
                var loadItems = Math.min(displayLen + loadImagesCount, length);
                _this.displayPictures = _this.displayPictures.concat(_this.pictures.slice(displayLen, loadItems));
                _this.changeDetection.markForCheck();
            }
        });
        this.initFancyBox();
        this.openPic();
        jQuery(document).on('contextmenu', 'img.fancybox-image', this.preventDownload);
        jQuery(document).on('dragstart', 'img.fancybox-image', this.preventDownload);
    };
    FancyAlbumComponent.prototype.ngOnDestroy = function () {
        jQuery.fancybox.defaults.caption = undefined;
        jQuery(document).off(this.fancyConfig);
        jQuery(document).off('contextmenu', 'img.fancybox-image', this.preventDownload);
        jQuery(document).off('dragstart', 'img.fancybox-image', this.preventDownload);
    };
    Object.defineProperty(FancyAlbumComponent.prototype, "sizeClass", {
        get: function () {
            var _a;
            if (this.size) {
                return _a = {},
                    _a["size-" + this.size] = true,
                    _a;
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FancyAlbumComponent.prototype, "sizeVal", {
        get: function () {
            return this.size || 'big';
        },
        enumerable: true,
        configurable: true
    });
    FancyAlbumComponent.prototype.openPic = function () {
        var _this = this;
        if (this.pic) {
            var click_1 = function () {
                if (_this.pictures.length) {
                    jQuery("[data-pic-id=\"" + _this.pic + "\"]").click();
                }
                else {
                    setTimeout(click_1, 1e1);
                }
            };
            click_1();
        }
    };
    FancyAlbumComponent.prototype.initFancyBox = function () {
        var _this = this;
        jQuery.fancybox.defaults.hash = false;
        jQuery.fancybox.defaults.caption = function (instance, item) {
            var id = item.opts.picId;
            if (typeof id === 'string') {
                id = +id.replace('pic-', '');
            }
            var post = _this.pictures.find(function (i) { return i.id === id; });
            var tmpl = "<div class=\"fb-like\" id=fb-like-" + id + "\n      data-href=\"https://lidikart.com\"\n      data-layout=\"standard\"\n      data-action=\"like\"\n      data-share=\"true\"\n      data-show-faces=\"false\">\n      </div>";
            return "<span>" + post.title.rendered + "</span>" + tmpl;
        };
        jQuery.fancybox.defaults.thumbs.autoStart = true;
        jQuery(document).on(this.fancyConfig);
    };
    return FancyAlbumComponent;
}());
export { FancyAlbumComponent };
