import { ProductionComponent } from '../components/production/production.component';
import { GalleryComponent } from '../components/gallery/gallery.component';
import { BiographyComponent } from '../components/biography/biography.component';
import { ExhibitionsComponent } from '../components/exhibitions/exhibitions.component';
import { ExhibitionComponent } from '../components/exhibition/exhibition.component';
import { StatementComponent } from '../components/statement/statement.component';
import { ContactsComponent } from '../components/contacts/contacts.component';
import { AppSettings } from '../constants';
var ɵ0 = {
    banner: true
}, ɵ1 = {
    banner: true
}, ɵ2 = {
    banner: true
};
export var ROUTES = [{
        path: '',
        component: GalleryComponent
    }, {
        path: AppSettings.ROUTE.GALLERY,
        component: GalleryComponent,
    }, {
        path: AppSettings.ROUTE.DECOR,
        component: ProductionComponent
    }, {
        path: 'about',
        component: BiographyComponent,
        data: ɵ0
    }, {
        path: 'exhibitions',
        component: ExhibitionsComponent
    }, {
        path: 'exhibitions/:id',
        component: ExhibitionComponent
    }, {
        path: 'statement',
        component: StatementComponent,
        data: ɵ1
    }, {
        path: 'contacts',
        component: ContactsComponent,
        data: ɵ2
    }, {
        path: AppSettings.ROUTE.BLOG,
        loadChildren: '../blog/blog.module#BlogModule'
    }
];
export { ɵ0, ɵ1, ɵ2 };
