import { OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { map, filter, switchMap, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { AppDataService } from '../../services/app-data.service';
import { AppSettings } from '../../constants';
import { selectGalleryImages, selectPageCategories, selectGallery, selectCategoryById } from '../../store/reducers';
import { loadGalleryPosts, selectGalleryCategory } from '../../store/actions/posts';
var GalleryComponent = /** @class */ (function () {
    function GalleryComponent(store, appService, route) {
        var _this = this;
        this.store = store;
        this.appService = appService;
        this.route = route;
        this.categories$ = of([]);
        this.menuCategories$ = of([]);
        this.albumState = {};
        this.destroy = new Subject();
        this.slug = AppSettings.ROUTE.GALLERY;
        this.categories$ = this.store.pipe(takeUntil(this.destroy), select(selectPageCategories(this.slug)), distinctUntilChanged(function (p, q) { return p.join() === q.join(); }), map(function (categories) { return categories.filter(function (c) { return !(c.slug && c.slug.includes('-no-show')); }); }));
        this.menuCategories$ = this.categories$.pipe(map(function (categories) { return categories.filter(function (c) { return c.slug !== 'general'; }); }));
        this.categories$.pipe(takeUntil(this.destroy), filter(function (categories) { return Boolean(categories.length); })).subscribe(function (categories) {
            _this.store.dispatch(loadGalleryPosts({ categories: categories.map(function (c) { return c.id; }) }));
        });
        this.pictures = this.store.select(selectGalleryImages);
        this.serie$ = this.store.pipe(takeUntil(this.destroy), select(selectGallery), switchMap(function (_a) {
            var category = _a.category;
            return _this.store.select(selectCategoryById, category);
        }));
        this.route.params.subscribe(function (params) {
            _this.albumState = params;
            var serie = params.serie ? Number(params.serie) : null;
            _this.store.dispatch(selectGalleryCategory({ category: serie }));
        });
    }
    GalleryComponent.prototype.ngOnDestroy = function () {
        this.destroy.next();
        this.destroy.complete();
    };
    Object.defineProperty(GalleryComponent.prototype, "urlPath", {
        get: function () {
            return "/" + this.appService.langURLPrefix + "/" + this.slug;
        },
        enumerable: true,
        configurable: true
    });
    GalleryComponent.prototype.menuClick = function () {
        $('html, body').animate({ scrollTop: 0 });
    };
    return GalleryComponent;
}());
export { GalleryComponent };
