import { Injectable } from '@angular/core';
import { Actions, Effect, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';

import * as PostsActions from '../actions/posts';
import { error } from '../actions';
import { WpService } from '../../services/wp.service';

@Injectable()
export class PostsEffects {
  constructor(private actions$: Actions, private wpService: WpService) {}

  gallery = createEffect(() => this.actions$.pipe(
    ofType(PostsActions.loadGalleryPosts),
    switchMap(({categories}) => this.wpService.loadPostsByCategories(categories)),
    map(posts => PostsActions.loadedGalleryPosts({posts})),
    catchError(e => of(error({message: 'Gallery images failed to load correctly.'})))));

  wallPaintings = createEffect(() => this.actions$.pipe(
    ofType(PostsActions.loadWallPaintingPosts),
    switchMap(({categories}) => this.wpService.loadPostsByCategories(categories)),
    map(posts => PostsActions.loadedWallPaintingPosts({posts})),
    catchError(e => of(error({message: 'Wall paintings failed to load correctly.'})))));

  exhibition = createEffect(() => this.actions$.pipe(
    ofType(PostsActions.loadExhibitionPosts),
    switchMap(({categories}) => this.wpService.loadPostsByCategories(categories)),
    map(posts => PostsActions.loadedExhibitionPosts({posts})),
    catchError(e => of(error({message: 'Exhibition images failed to load correctly.'})))));
}
