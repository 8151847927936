import { Component, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { map, filter, switchMap, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';

import { AppDataService } from '../../services/app-data.service';
import { AppSettings } from '../../constants';
import { WpCategory, WpPost } from '../../interfaces';
import { AppState, selectGalleryImages, selectPageCategories, selectGallery, selectCategoryById } from '../../store/reducers';
import { loadGalleryPosts, selectGalleryCategory } from '../../store/actions/posts';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss', '../../styles/sub-navigation.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GalleryComponent implements OnDestroy {
  categories$: Observable<WpCategory[]> = of([]);
  menuCategories$: Observable<WpCategory[]> = of([]);
  pictures: Observable<WpPost[]>;
  albumState: {pic?: string} = {};
  serie$: Observable<WpCategory>;
  destroy = new Subject();

  private slug = AppSettings.ROUTE.GALLERY;

  constructor(
    private store: Store<AppState>,
    private appService: AppDataService,
    private route: ActivatedRoute) {
      this.categories$ = this.store.pipe(
        takeUntil(this.destroy),
        select(selectPageCategories(this.slug)),
        distinctUntilChanged((p, q) => p.join() === q.join()),
        map(categories => categories.filter(c => !(c.slug && c.slug.includes('-no-show')))));

      this.menuCategories$ =  this.categories$.pipe(map(categories => categories.filter(c => c.slug !== 'general')));
      this.categories$.pipe(
        takeUntil(this.destroy),
        filter(categories => Boolean(categories.length))).subscribe(categories => {
        this.store.dispatch(loadGalleryPosts({categories: categories.map(c => c.id)}));
      });
      this.pictures = this.store.select(selectGalleryImages);

      this.serie$ = this.store.pipe(
        takeUntil(this.destroy),
        select(selectGallery),
        switchMap(({category}) => this.store.select(selectCategoryById, category)));

      this.route.params.subscribe(params => {
        this.albumState = params;
        const serie = params.serie ? Number(params.serie) : null;
        this.store.dispatch(selectGalleryCategory({category: serie}));
      });
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  get urlPath() {
    return `/${this.appService.langURLPrefix}/${this.slug}`;
  }

  menuClick() {
    $('html, body').animate({scrollTop: 0});
  }
}
