import { OnInit, ElementRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { fromEvent, merge } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { AppDataService } from './services/app-data.service';
import { selectPages, selectRouteData, selectRouterInfo, selectPageBySlug } from './store/reducers';
import { loadPages } from './store/actions/pages';
import { loadCategories } from './store/actions/categories';
import { AppSettings } from './constants';
import { dismissError } from './store/actions';
import { Title } from '@angular/platform-browser';
var AppComponent = /** @class */ (function () {
    function AppComponent(store, router, appService, titleService) {
        this.store = store;
        this.router = router;
        this.appService = appService;
        this.titleService = titleService;
        this.pagesOrderMap = {};
        this.contentHeight = 0;
    }
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dispatchLoad();
        this.appService.translate.onLangChange.subscribe(this.dispatchLoad.bind(this));
        this.errorList$ = this.store.select('errorList');
        var resizeStream = fromEvent(window, 'resize');
        var routeChangeStream = this.router.events.pipe(filter(function (e) { return e instanceof NavigationEnd; }));
        merge(resizeStream, routeChangeStream).pipe(switchMap(function () { return _this.store.select(selectRouteData); })).subscribe(function (data) {
            _this.setContentHeight(data.banner);
        });
        this.store.pipe(select(selectPages), map(function (p) { return p.reduce(function (pages, _a) {
            var slug = _a.slug, menu_order = _a.menu_order;
            pages[slug] = menu_order;
            return pages;
        }, {}); })).subscribe(function (orderMap) {
            _this.pagesOrderMap = orderMap;
        });
        this.store.select(selectRouterInfo).pipe(filter(function (info) { return Boolean(info); }), map(function (info) {
            var langPrefix = _this.appService.langURLPrefix ? "/" + _this.appService.langURLPrefix + "/" : '/';
            return info.url.replace(langPrefix, '').split('/')[0];
        }), switchMap(function (slug) { return _this.store.select(selectPageBySlug, slug || AppSettings.ROUTE.GALLERY); }), filter(function (p) { return Boolean(p); }))
            .subscribe(function (page) {
            _this.titleService.setTitle("LidikArt - " + page.title.rendered);
        });
    };
    AppComponent.prototype.dispatchLoad = function () {
        this.store.dispatch(loadPages());
        this.store.dispatch(loadCategories());
    };
    AppComponent.prototype.getState = function (outlet) {
        if (outlet.activated) {
            var path = this.getPath(outlet.activatedRoute.snapshot);
            var langPrefix = this.appService.langURLPrefix;
            var newPage = langPrefix ? path.replace(langPrefix + "/", '') : path;
            if (!this.pagesOrderMap[newPage]) {
                newPage = AppSettings.ROUTE.GALLERY;
            }
            var isNext = this.prevPage <= this.pagesOrderMap[newPage];
            this.prevPage = this.pagesOrderMap[newPage];
            return {
                value: newPage,
                params: {
                    offsetEnter: isNext ? 100 : -100,
                    offsetLeave: isNext ? -100 : 100
                }
            };
        }
    };
    AppComponent.prototype.getPath = function (snapshot) {
        var path = snapshot.routeConfig.path;
        if (path) {
            return path;
        }
        if (snapshot.parent.routeConfig) {
            return snapshot.parent.routeConfig.path;
        }
        return path;
    };
    AppComponent.prototype.dismissError = function (error) {
        this.store.dispatch(dismissError({ message: error }));
    };
    AppComponent.prototype.getHeight = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return args.reduce(function (sum, el) {
            return sum + el.nativeElement.offsetHeight;
        }, 0);
    };
    AppComponent.prototype.setContentHeight = function (showBanner) {
        if (showBanner === void 0) { showBanner = false; }
        var inPage = [this.header];
        if (showBanner) {
            inPage.push(this.footer);
        }
        this.contentHeight = window.innerHeight - this.getHeight.apply(this, inPage) -
            (showBanner ? 101 : 1) + 'px';
    };
    return AppComponent;
}());
export { AppComponent };
